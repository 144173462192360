import { useContext, useState, ReactElement } from 'react';
import { Waypoint } from 'react-waypoint';
import { Card } from 'primereact/card';
import { Sidebar } from 'primereact/sidebar';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { getSectionByType, SectionEnums, WHAT_WE_DO_BEST, WHAT_WE_DO_BEST_SECTION } from '../../../../enums/sectionsTypes';
import { NavBarContext } from '../../../../context/globalContext';
import WhatWeDoBestDetail from './detail/WhatWeDoBestDetail';
import { IWhatWeDoBestDetailModel, WhatWeDoBestDetailModel } from '../../../../models/what-we-do-best/WhatWeDoBestModel';
import SidebarControl from '../../../../components/ui/sidebar-control/SidebarControl';
import Footer from '../../../../components/layout/footer/Footer';
import './WhatWeDoBest.scss';

const WhatWeDoBest = () => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const { setActiveSection } = useContext(NavBarContext);
  const [currentDetail, setCurrentDetail] = useState<IWhatWeDoBestDetailModel>(new WhatWeDoBestDetailModel());
  const [displayModal, setDisplayModal] = useState(false);
  const [displayFullScreen, setDisplayFullScreen] = useState(false);
  const sectionHeaderText = getSectionByType(SectionEnums.WHATWEDOBEST, false);

  const whatWeDoBestList = t('what-we-do-best-detail', { returnObjects: true }) as Array<IWhatWeDoBestDetailModel>;

  const handleWaypointEnter = () => {    
    setActiveSection(SectionEnums.WHATWEDOBEST);
  };

  const handleWaypointLeave = () => {};

  const openDetail = (detail: IWhatWeDoBestDetailModel) => {
    setCurrentDetail(detail);
    if (isMobile) {
      setDisplayFullScreen(true);
    } else {
      setDisplayModal(true);
    }
  };

  const content = (whatWeDoBestDetail: IWhatWeDoBestDetailModel): JSX.Element => (
    <Card key={whatWeDoBestDetail.id} className={`card-bg-image ${isMobile ? 'mobile' : 'desktop'}`}>
      <div className='gradient-background'></div>
      <div title={whatWeDoBestDetail.title} className='p-col-12 icon-title ' onClick={() => openDetail(whatWeDoBestDetail)}>
        <div className={`icon ${whatWeDoBestDetail.classname}`} />
        <div className='p-card-title'>{whatWeDoBestDetail.title}</div>
      </div>
    </Card>
  );

  const renderWhatWeDoBest = () => {
    let whatWeDoBestContent = Array<ReactElement>();
    whatWeDoBestList.forEach(wwdb => {
      whatWeDoBestContent.push(content(wwdb));
    });
    return whatWeDoBestContent;
  };

  const whatWeDoBestModalDetail = (
    <WhatWeDoBestDetail
      id={currentDetail.id}
      title={currentDetail.title}
      description={currentDetail.description}
      benefits={currentDetail.benefits}
      imageUrl={currentDetail.imageUrl}
      why={currentDetail.why}
      classname={currentDetail.classname}
    ></WhatWeDoBestDetail>
  );

  return (
    <Waypoint debug={false} topOffset={'254px'} bottomOffset={'559px'} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      <div>
        <h1 id={WHAT_WE_DO_BEST_SECTION}>{sectionHeaderText}</h1>
        <div className={`${isMobile ? 'mobile' : 'desktop p-d-flex p-flex-wrap p-jc-between'} cards-container`}>{renderWhatWeDoBest()}</div>

        <Dialog
          header={currentDetail.title}
          visible={displayModal}
          modal={true}
          style={{ width: '839px' }}
          onHide={() => setDisplayModal(false)}
          draggable={false}
          resizable={false}
          blockScroll={true}
        >
          {whatWeDoBestModalDetail}
        </Dialog>

        <Sidebar visible={displayFullScreen} fullScreen blockScroll={true} onHide={() => setDisplayFullScreen(false)}>
          <SidebarControl header={currentDetail.title} onBackClick={() => setDisplayFullScreen(false)} showBack={true}></SidebarControl>
          {whatWeDoBestModalDetail}
          <Footer></Footer>
        </Sidebar>
      </div>
    </Waypoint>
  );
};

export default WhatWeDoBest;
