import { useContext, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import CustomAvatar from '../../../../components/ui/avatar/CustomAvatar';
import { NavBarContext } from '../../../../context/globalContext';
import { getSectionByType, SectionEnums, OUR_TECH_STACK, OUR_TECH_STACK_SECTION } from '../../../../enums/sectionsTypes';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { LOGOS } from '../../../../assets/images/tech-stacks';
import './OurTechStack.scss';

const OurTechStack = () => {
  const isMobile = useCheckMobileScreen();
  const { setActiveSection } = useContext(NavBarContext);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayFullScreen, setDisplayFullScreen] = useState(false);
  const sectionHeaderText = getSectionByType(SectionEnums.OURTECHSTACK, false);

  const handleWaypointEnter = () => {
    setActiveSection(SectionEnums.OURTECHSTACK);
  };

  const handleWaypointLeave = () => {};

  const getLogos = LOGOS.map((item, index) => {
    return (
      <div key={index} title={item.name} className='technology'>
        <div className='gradient-background'></div>
        <img src={item.logo} className='logo' alt={item.name} />
        <span className='technology-name'>{item.name}</span>
      </div>
    );
  });

  return (
    <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      <div>
        <h1 id={OUR_TECH_STACK_SECTION}>{sectionHeaderText}</h1>
        <div className='center'>
          <div className={`p-grid p-lg-12 stack-container ${isMobile ? 'mobile' : 'desktop'}`}>{getLogos}</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default OurTechStack;
