import { useTranslation } from 'react-i18next';
import { PRIVACYPOLICYLINK } from '../../../assets/documents';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <a href={PRIVACYPOLICYLINK} target='_blank'>
        {t('footer.Privacy Policy')}
      </a>
    </>
  );
};

export default PrivacyPolicy;
