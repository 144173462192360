import React, { useEffect, useState } from 'react';

const onTopScroll = () => {
  const [scrollOnTop, setScrollOnTop] = useState(true);
  const handleScroll = () => {
    const onTop = window.pageYOffset === 0;
    setScrollOnTop(onTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollOnTop;
};

export default onTopScroll;
