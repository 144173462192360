import React from 'react';
import { getSectionByType, SectionEnums } from '../enums/sectionsTypes';

export interface INavbarContext {
  activeSection: string;
  setActiveSection: (section: SectionEnums) => void;
  goToSection: (sectionName: string) => void;
}

export const defaultNavBarState: INavbarContext = {
  activeSection: getSectionByType(SectionEnums.LANDING),
  setActiveSection: (section: SectionEnums) => {},
  goToSection: (sectionName: string) => {},
};

const NavbarContext = React.createContext({
  activeSection: '',
  setActiveSection: (section: SectionEnums) => {},
  goToSection: (sectionName: string) => {},
});

export default NavbarContext;
