import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import koybotIconSvg from '../../../../assets/images/design/koybot.svg';
import { getSectionByType, SectionEnums } from '../../../../enums/sectionsTypes';
import { KoyBotContext, NavBarContext } from '../../../../context/globalContext';
import { KoybotContextType } from '../../../../models/koybotContextType';
import SidebarControl from '../../../../components/ui/sidebar-control/SidebarControl';
import ContactUs from '../../../contact-us/ContactUs';
import FauxChat from '../../../faux-chat/FauxChat';
import './LetsChat.scss';

const LetsChat = () => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const { setActiveSection } = useContext(NavBarContext);
  const { koybotVisibility, setKoybotVisibility } = useContext(KoyBotContext) as KoybotContextType;

  const sectionHeaderText = getSectionByType(SectionEnums.LETSCHAT, false);
  const buttonText = t('GIVE IT A GO');
  const contentText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dignissim non urna ac molestie. Praesent quis erat eu mi accumsan lacinia. Duis ullamcorper lacus sit amet pharetra venenatis. Sed scelerisque dictum mi nec blandit. Nulla facilisi.';

  const handleWaypointEnter = () => {
    setActiveSection(SectionEnums.LETSCHAT);
  };

  const handleWaypointLeave = () => {};

  const onOpenChatClick = () => {
    setKoybotVisibility(true);
  };
  const onHide = () => {
    setKoybotVisibility(false);
  };

  const desktopContent = () => (
    <>
      <div className='desktop koybot'>
        <div className='header'>
          <span>{t('chatbot.LETS HAVE A CHAT')}</span>
        </div>
        <div className='circle-1'></div>
        <div className='circle-2'></div>
        <div className='circle-icon' onClick={() => onOpenChatClick()}>
          <img src={koybotIconSvg} alt='Chatbot Icon'></img>
        </div>
      </div>
      <Dialog
        className='chatbot-dialog'
        header='Kontact us'
        visible={koybotVisibility}
        position='bottom-right'
        modal
        style={{ width: '428px' }}
        onHide={() => onHide()}
        draggable={false}
        resizable={false}
        blockScroll={true}
      >
        {/* <FauxChat></FauxChat> */}
        <ContactUs></ContactUs>
      </Dialog>
    </>
  );

  const mobileContent = () => (
    <Waypoint debug={false} topOffset={'465px'} bottomOffset={'709px'} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      <div className='koybot-section-mobile'>
        {/* <h1 className=''>{sectionHeaderText}</h1>
        <div className='p-grid center'>
          <Fragment>
            <div className='p-grid center p-col-12'>
              <div className='p-col-2 center'>
                <h2 className='arroba'>@</h2>
              </div>
              <div className='p-col-10 paragraph'>
                <p className='lets-chat-text'>{contentText}</p>
              </div>

              <div className='p-col-12 center'>
                <div className='p-col-6 right'>
                  <img className='lets-chat-icon' src={koybotIconSvg} alt='Chatbot Icon'></img>
                </div>
                <div className='p-col-6 left'>
                  <Button className='koyku-button' label={buttonText} onClick={onOpenChatClick}></Button>
                </div>
              </div>
            </div>
          </Fragment>
        </div> */}
        <Sidebar visible={koybotVisibility} fullScreen onHide={() => onHide()} className='chatbot-sidebar'>
          <SidebarControl header={'Kontact us'} onBackClick={() => onHide()} showBack={true}></SidebarControl>
          {/* <FauxChat></FauxChat> */}
          <ContactUs></ContactUs>
        </Sidebar>
      </div>
    </Waypoint>
  );

  const renderContent = () => {
    if (isMobile) {
      return mobileContent();
    } else {
      return desktopContent();
    }
  };

  return renderContent();
};

export default LetsChat;
