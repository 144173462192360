import React, { FormEventHandler } from 'react';
import { t } from 'i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ContactUsModel, VALIDATIONS, SetPropertyFunction } from '../../../models/contact-us/ContactUsModel';
import { PRIVACYPOLICYLINK } from '../../../assets/documents';
import './ContactUsForm.scss';

interface IContactUsForm {
  siteKey: string;
  model: ContactUsModel;
  recaptchaRef: React.RefObject<ReCAPTCHA>;
  setProperty: SetPropertyFunction;
  formSubmitHandler: FormEventHandler<HTMLFormElement>;
  isProcessing: boolean;
}

const ContactUsForm = ({ siteKey, model, recaptchaRef, setProperty, formSubmitHandler, isProcessing }: IContactUsForm) => {
  const getContactForm = (
    <form className='p-mt-5 p-mb-5 p-pl-4 p-pr-4' onSubmit={formSubmitHandler}>
      <div className='p-fluid p-formgrid p-grid'>
        <div className='p-field p-col-12'>
          <InputText
            id='fullName'
            name='fullName'
            type='text'
            value={model.fullName}
            onChange={e => setProperty('fullName', e.target.value)}
            required
            placeholder={`${t('contact-us.Your name')}*`}
            maxLength={VALIDATIONS.maxFullName}
          />
        </div>
        <div className='p-field p-col-12'>
          <InputText
            id='email'
            name='email'
            type='email'
            value={model.email}
            onChange={e => setProperty('email', e.target.value)}
            required
            placeholder={`${t('contact-us.Email')}*`}
            maxLength={VALIDATIONS.maxEmail}
          />
        </div>
        <div className='p-field p-col-12'>
          <InputText
            id='phoneNumber'
            name='phoneNumber'
            type='text'
            value={model.phoneNumber}
            onChange={e => setProperty('phoneNumber', e.target.value)}
            placeholder={`${t('contact-us.Phone number')}`}
            maxLength={VALIDATIONS.maxPhoneNumber}
          />
        </div>
        <div className='p-field p-col-12'>
          <InputTextarea
            id='message'
            name='message'
            rows={10}
            value={model.message}
            onChange={e => setProperty('message', e.target.value)}
            required
            placeholder={`${t('contact-us.Your message')}*`}
            autoResize={true}
            maxLength={VALIDATIONS.maxMessage}
          />
        </div>
        <div className='p-col-12 privacy'>
          <Checkbox
            inputId='hasAgreed'
            name='hasAgreed'
            onChange={e => setProperty('hasAgreed', e.checked)}
            checked={model.hasAgreed}
            required
          ></Checkbox>
          <label htmlFor='hasAgreed' className='p-checkbox-label p-ml-2'>
            {`${t('contact-us.Do you agree with our')} `}
            <a href={PRIVACYPOLICYLINK} target='_blank'>
              {`${t('contact-us.terms and conditions')}`}
            </a>
          </label>
        </div>
        <div className='p-offset-1 p-col-10 send'>
          <Button
            style={!model.hasAgreed ? { display: 'none' } : { display: 'block' }}
            className='p-mt-3 p-mb-3 center koyku-button'
            label={!isProcessing ? t('contact-us.SEND')?.toString() : '...submitting'}
            disabled={isProcessing}
          ></Button>
        </div>
      </div>
      <ReCAPTCHA className='center' ref={recaptchaRef} sitekey={siteKey} size='invisible' badge='inline' theme='dark' />
    </form>
  );
  return <>{getContactForm}</>;
};

export default ContactUsForm;
