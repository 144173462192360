import React from 'react';
import { InputText } from 'primereact/inputtext';
import './ChatInput.scss';
import planeIcon from './../../../assets/images/design/plane.svg';

interface IChatInput {
  className?: string;
  spanParentClassName?: string;
  iconClassName?: string;
  inputTextClassName?: string;
  placeholder?: string;
  chatInputText?: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHandler: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickHandler: () => void;
}

/**
 * Faux chat text input component.
 * @param spanParentClassName
 * OPTIONAL: CSS for parent span
 * @param iconClassName
 * OPTIONAL: CSS for icon
 * @param inputTextClassName
 * OPTIONAL: CSS for input textbox
 * @param placeholder
 * OPTIONAL:
 * @param chatInputText
 * OPTIONAL: The typed in text
 * @param onChangeHandler
 * REQUIRED: Callback for when text is typed into the textbox
 * @param onClickHandler
 * REQUIRED: Callback for when the send icon is clicked
 * @param onKeyDownHandler
 * REQUIRED: Callback for when the enter key is pressed
 * @returns
 */
const ChatInput = ({
  spanParentClassName,
  iconClassName,
  inputTextClassName,
  placeholder,
  chatInputText,
  onChangeHandler,
  onClickHandler,
  onKeyDownHandler,
}: IChatInput) => {
  return (
    <span className={'p-input-icon-right full-width ' + spanParentClassName}>
      <img src={planeIcon} className='icon' onClick={onClickHandler} alt='Send'></img>
      <InputText
        className={inputTextClassName}
        placeholder={placeholder}
        value={chatInputText}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        maxLength={250}
      />
    </span>
  );
};

export default ChatInput;
