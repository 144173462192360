import { IconProp, config } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import react, { ProviderProps, ReactChild, ReactChildren } from 'react';
import { Link } from 'react-router-dom';
import koykuCircleLogo from '../../../assets/images/design/koyku-circle-logo.svg';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import './SidebarControl.scss';

interface Props {
  onBackClick: () => void;
  showBack: boolean;
  header: string;
}

const SidebarControl = (props: Props) => {
  const faChevronLeftIcon = faChevronLeft as IconProp;

  return (
    <div className='sidebar-left-button-group center'>
      <FontAwesomeIcon
        className={`back-icon ${props.showBack ? '' : 'hide'}`}
        icon={faChevronLeftIcon}
        onClick={() => props.onBackClick()}
      ></FontAwesomeIcon>
      <div className='header-title'>{props.header}</div>
      {/* <Link to='/' className='home-icon'>
        <img className='koyku-icon' src={koykuCircleLogo} alt='Koyku Icon'></img>
      </Link> */}
    </div>
  );
};

export default SidebarControl;
