import { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { apiRoutes } from '../../config/routes/apiRoutes';
import { KoyBotContext } from '../../context/globalContext';
import { ContactUsModel, SetPropertyFunction } from '../../models/contact-us/ContactUsModel';
import { KoybotContextType } from '../../models/koybotContextType';
import { get, post } from '../../utils/api/apiUtils';
import ContactUsForm from './components/ContactUsForm';
import './ContactUs.scss';

//TODO: Finish styling
//TODO: Add into modal
//TODO: Block submit button on click
//TODO: Add CSRF
//TODO: Create managed azure function for sending email

const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHAV2_SITEKEY as string;

const ContactUs = () => {
  const defaultModel = new ContactUsModel();
  const [model, setModel] = useState<ContactUsModel>(defaultModel);
  const [isProcessing, setIsProcessing] = useState(false);
  const { setKoybotVisibility } = useContext(KoyBotContext) as KoybotContextType;

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const setProperty: SetPropertyFunction = (property, value) => {
    setModel({
      ...model,
      [property]: value,
    });
  };

  // const getCSRFToken = async () => {
  //   const response = await get(apiRoutes.CSRF);
  //   //axios.defaults.headers.post['X-CSRF-Token'] = response.data.CSRFToken;
  // };

  // useEffect(() => {
  //   getCSRFToken();
  // }, []);

  const formSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsProcessing(true);

    //TODO: Show toast or message if terms and conditions haven't been agreed to
    if (!model.hasAgreed) {
      //setIsProcessing(false);
      //console.log('Please read and accept the terms and conditions');
      return;
    }

    //TODO: CSRF
    //Execute recaptcha
    const response = await recaptchaRef.current?.executeAsync();
    if (response) {
      //If recaptcha is successful, proceed with the email
      var result = await post(apiRoutes.SENDEMAIL, model).catch(e => {
        //console.log('Error in request: Csrf token probably failed...', e);
        setIsProcessing(false);
      });

      //TODO: For result show success or error toast
      //console.log('Email result: ', result);
    } else {
      //TODO: Show error toast
      //console.log('Error: Captcha failed...');
      setIsProcessing(false);
      return;
    }

    setIsProcessing(false);

    //TODO: Set timer after showing toast and then close the form
    setKoybotVisibility(false);
  };

  return (
    <>
      {
        <ContactUsForm
          siteKey={SITE_KEY}
          model={model}
          recaptchaRef={recaptchaRef}
          setProperty={setProperty}
          formSubmitHandler={formSubmitHandler}
          isProcessing={isProcessing}
        ></ContactUsForm>
      }
    </>
  );
};

export default ContactUs;
