export interface IOurWorkModel {
  id: number;
  title: string;
  date: string;
  description: string;
  image: string;
  stack: string[];
  detail: IOurWorkDetailModel;
}

export interface IOurWorkDetailModel {
  theClient: string;
  theChallenge: string;
  ourSolution: string;
  results: string;
}

export class OurWorkModel implements IOurWorkModel {
  id: number = 0;
  title: string = '';
  date: string = '';
  description: string = '';
  image: string = '';
  stack: string[] = [];
  detail: IOurWorkDetailModel = new OurWorkDetailModel();
}

export class OurWorkDetailModel implements IOurWorkDetailModel {
  theClient: string = '';
  theChallenge: string = '';
  ourSolution: string = '';
  results: string = '';
}
