import p1 from './p1.png';
import p2 from './p2.png';
import p3 from './p3.png';
import p4 from './p4.png';
import p5 from './p5.png';

export const getImage = (key: string) => {
  switch (key) {
    case 'p1':
      return p1;
    case 'p2':
      return p2;
    case 'p3':
      return p3;
    case 'p4':
      return p4;
    case 'p5':
      return p5;
    default:
      return p1;
  }
};
