import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import PrivacyPolicy from '../../shared/privacy-policy/PrivacyPolicy';
import youtube from '../../../assets/images/social-icons/youtube.svg';
import linkedin from '../../../assets/images/social-icons/linkedin.svg';
import facebook from '../../../assets/images/social-icons/facebook.svg';
import instagram from '../../../assets/images/social-icons/instagram.svg';
import twitter from '../../../assets/images/social-icons/twitter.svg';
import './Footer.scss';

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const isMobile = useCheckMobileScreen();

  return (
    <div className={(isMobile ? 'mobile' : 'desktop') + ' footer'}>
      <div className='social-networks'>
        <div className='social-networks__container'>
          <span className='social-networks__header'>{t('footer.You can also find us at')}</span>
          <div className='social-networks__links-container'>
            <a href={t('footer.linkedin')?.toString()} target='_blank'>
              <img src={linkedin} alt='LinkedIn'></img>
            </a>
            <a href={t('footer.twitter')?.toString()} target='_blank'>
              <img src={twitter} alt='Twitter'></img>
            </a>
            <a href={t('footer.facebook')?.toString()} target='_blank'>
              <img src={facebook} alt='Facebook'></img>
            </a>
            <a href={t('footer.youtube')?.toString()} target='_blank'>
              <img src={youtube} alt='Youtube'></img>
            </a>
            {/*<a href={t('footer.instagram')} target='_blank'>
              <img src={instagram} alt='Instagram'></img>
            </a>*/}
          </div>
        </div>
      </div>
      <div className='other-links'>
        {/* <Link to={{ pathname: 'https://example1.com' }} target='_blank'>
          Lorem ipsum link of interest 1
        </Link>
        <Link to={{ pathname: 'https://example2.com' }} target='_blank'>
          Lorem ipsum link of interest 2
        </Link>
        <Link to={{ pathname: 'https://example3.com' }} target='_blank'>
          Lorem ipsum link of interest 3
        </Link> */}
        <PrivacyPolicy></PrivacyPolicy>
        <div className='kopyright'>© koyku {year}</div>
      </div>
    </div>
  );
};

export default Footer;
