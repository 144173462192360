import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Sidebar } from 'primereact/sidebar';
import LanguageSelector from '../../shared/language-selector/LanguageSelector';
import menuIcon from '../../../assets/images/design/menu-icon.png';
import koykuCircleLogo from '../../../assets/images/design/koyku-circle-logo.svg';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { KoyBotContext, NavBarContext } from '../../../context/globalContext';
import onTopScroll from '../../../hooks/onTopScroll';
import { LANDING } from '../../../enums/sectionsTypes';
import './Navbar.scss';
import { KoybotContextType } from '../../../models/koybotContextType';

const Navbar = () => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const navbarContext = useContext(NavBarContext);
  const EXCLUDE_ON_DESKTOP = 4;
  const { koybotVisibility, setKoybotVisibility } = useContext(KoyBotContext) as KoybotContextType;

  let navBarItems = t('nav-menu', { returnObjects: true }) as string[];

  const cleanName = (name: string) => {
    return name.replaceAll(' ', '-').toLocaleLowerCase();
  };

  const onClickAction = (index: number, section: string) => {
    if (index != 5) {
      navbarContext.goToSection(section);
    } else {
      setKoybotVisibility(true);
    }
  };

  const desktopLanguageSelector = () => {
    return (
      <div className={`${onTopScroll() ? 'top' : 'no-top'} desktop navbar`}>
        <div className='navbar__container'>
          <Link to='/' className='home-icon'>
            <img src={koykuCircleLogo} onClick={() => navbarContext.goToSection(LANDING)} alt='Koyku Icon'></img>
          </Link>
          <div className='nav-links-group'>
            {Object.keys(navBarItems).map((key: any, index) => (
              <div
                style={{ display: index != EXCLUDE_ON_DESKTOP ? 'visible' : 'none' }}
                key={key}
                className={`${key == navbarContext.activeSection ? 'active' : ''} ${'nav-links'}`}
                onClick={() => onClickAction(index, cleanName(key))}
              >
                {navBarItems[key]}
              </div>
            ))}
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      </div>
    );
  };

  const mobileLanguageSelector = () => {
    return (
      <>
        <div className={`${onTopScroll() ? 'top' : 'no-top'} mobile navbar p-d-flex p-jc-between`}>
          <Link to='/' className='home-icon'>
            <img src={koykuCircleLogo} className='kIcon' alt='Koyku Icon'></img>
          </Link>
          <img src={menuIcon} className='menuIcon' onClick={() => setVisibleFullScreen(true)} alt='Koyku Icon'></img>

          <Sidebar
            visible={visibleFullScreen}
            fullScreen
            blockScroll={true}
            onHide={() => setVisibleFullScreen(false)}
            className='mobile-navbar-sidebar'
          >
            <div className='link-group'>
              {Object.keys(navBarItems).map((key: any, index) => (
                <div
                  style={{ display: index != EXCLUDE_ON_DESKTOP ? 'visible' : 'none' }}
                  className={(key == navbarContext.activeSection ? 'active' : '') + ' nav-links'}
                  key={key}
                  onClick={() => {
                    onClickAction(index, cleanName(key));
                    setVisibleFullScreen(false);
                  }}
                >
                  <span>{navBarItems[key]}</span>
                </div>
              ))}
            </div>
            <LanguageSelector></LanguageSelector>
          </Sidebar>
        </div>
      </>
    );
  };

  if (!isMobile) {
    return <>{desktopLanguageSelector()}</>;
  } else {
    return <>{mobileLanguageSelector()}</>;
  }
};

export default Navbar;
