import './StepsSummary.scss';

interface Props {
  steps: string[];
  current: string | null;
  moveToSection: (value: string) => void;
}

const StepsSummary = ({ steps, current, moveToSection }: Props) => {
  const stepSize = 100 / (steps.length - 1);

  const changeTo = (newSection: string) => {
    moveToSection(newSection);
  };

  const renderPoint = (stepName: string, order: number) => {
    return (
      <div
        key={order}
        className={`point center ${order} ${stepName == current ? 'current' : ''}`}
        onClick={() => changeTo(stepName)}
        style={{ left: `${stepSize * order}%` }}
      >
        <div className='pointName' onClick={() => changeTo(stepName)}>
          {stepName}
        </div>
      </div>
    );
  };

  return (
    <div className='steps-summary'>
      <div className='track'></div>
      <div className='points-container'>{steps.map((stepName, index) => renderPoint(stepName, index))}</div>
    </div>
  );
};

export default StepsSummary;
