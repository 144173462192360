import React, { useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import koybotIcon from '../../assets/images/design/koybot.svg';
import ChatInput from './components/ChatInput';
import ChatText from './components/ChatText';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import './FauxChat.scss';

/**
 * Primary faux chat component and container.
 * @returns
 */
const FauxChat = () => {
  const isMobile = useCheckMobileScreen();
  const [chatUsername, setChatUsername] = useState('');
  const [chatInputText, setChatInputText] = useState('');
  const [chatQueryText, setChatQueryText] = useState(
    'Phasellus ut ultrices lectus. Fusce pharetra pretium erat, a bibendum libero gravida eu. Integer dictum mi at nunc tincidunt, vitae tincidunt erat imperdiet. Donec vel nisi justo.',
  );
  const [chatResponseText, setChatResponseText] = useState('Nulla ac justo at mi luctus dapibus.');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    //console.log('Chat Text -> ', value);
    setChatInputText(value);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterKey = e.code == 'Enter';
    if (isEnterKey && chatInputText) {
      setChatQueryText(chatInputText);
      setChatInputText('');
    }
  };

  const onClickHandler = () => {
    if (chatInputText) {
      setChatQueryText(chatInputText);
      setChatInputText('');
    }
  };

  //const introText = 'Hello, I am KOYBOT';
  const introText = 'Kontact us';
  const introBody =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae risus ultrices, molestie sapien non, cursus augue.';

  const response = () => {
    return (
      <ChatText
        username='KOYBOT'
        spanParentClassName='p-col chat chat-response'
        spanUserClassName='p-grid p-col-12'
        text={chatResponseText}
      ></ChatText>
    );
  };
  const query = () => {
    return <ChatText username='' spanParentClassName='p-col chat chat-query' text={chatQueryText}></ChatText>;
  };

  return (
    <>
      <ScrollPanel className={isMobile ? 'mobile' : 'desktop'}>
        {/** Koybot Intro Section */}
        <div className='p-grid p-col'>
          <div className='p-col p-col-3'>
            <Image className='p-col-12' imageClassName='p-col-12' src={koybotIcon}></Image>
          </div>
          <div className='p-grid p-col-9'>
            <span hidden={!isMobile} className='p-col-12'>
              <strong>{introText}</strong>
            </span>
            <span className='p-col-12'>{introBody}</span>
          </div>
        </div>

        {/** Chat Options Section */}
        <div className='p-grid'>
          <div className='p-col-12 p-px-0'>
            <div className='p-col-12'>
              <Button className='p-col-10 koyku-button' label='Praesent tellus'></Button>
            </div>
            <div className='p-col-12'>
              <Button className='p-col-7 koyku-button' label='Curabitur finibus'></Button>
              <Button className='p-col-5 koyku-button' label='Etiam nec'></Button>
            </div>
            <div className='p-col-12'>
              <Button className='p-col-12 koyku-button' label='Nam eu'></Button>
            </div>
          </div>
        </div>

        {/** Query/Response Section */}
        <div className='p-grid p-col-12 p-mt-3 p-mb-3'>
          <div className='p-col-12'>{response()}</div>
          <div className='p-col-12'>{query()}</div>
        </div>
      </ScrollPanel>

      {/** Text Input Section */}
      <Panel className={`p-mb-5 input-text ${isMobile ? 'mobile' : 'desktop'}`}>
        <ChatInput
          inputTextClassName='p-col-12'
          chatInputText={chatInputText}
          onChangeHandler={onChangeHandler}
          onClickHandler={onClickHandler}
          onKeyDownHandler={onKeyDownHandler}
        ></ChatInput>
      </Panel>
    </>
  );
};

export default FauxChat;
