import { t } from 'i18next';

/**TODO: Remove string constants and use enums. */
export const LANDING = 'LANDING';
export const LANDING_SECTION = 'landing';
export const LETS_CHAT_SECTION = 'lets-have-a-chat';
export const LETS_CHAT = `nav-menu.${LETS_CHAT_SECTION}`;
export const MEET_THE_TEAM_SECTION = 'meet-the-team';
export const MEET_THE_TEAM = `nav-menu.${MEET_THE_TEAM_SECTION}`;
export const SOME_OF_OUR_WORK_SECTION = 'some-of-our-work';
export const SOME_OF_OUR_WORK = `nav-menu.${SOME_OF_OUR_WORK_SECTION}`;
export const WHAT_WE_DO_BEST_SECTION = 'what-we-do-best';
export const WHAT_WE_DO_BEST = `nav-menu.${WHAT_WE_DO_BEST_SECTION}`;
export const OUR_TECH_STACK_SECTION = 'our-tech-stack';
export const OUR_TECH_STACK = `nav-menu.${OUR_TECH_STACK_SECTION}`;
export const CONTACT_US_SECTION = 'contact-us';
export const CONTACT_US = `nav-menu.${CONTACT_US_SECTION}`;

export enum SectionEnums {
  LANDING = 0,
  LETSCHAT = 2,
  MEETTHETEAM = 3,
  OURWORK = 4,
  WHATWEDOBEST = 5,
  OURTECHSTACK = 6,
  CONTACTUS = 7,
}

export const getSectionByType = (sectionType: SectionEnums, byId: boolean = false): string => {
  switch (sectionType) {
    case SectionEnums.LANDING:
      return !byId ? t(LANDING) : LANDING_SECTION;
    case SectionEnums.LETSCHAT:
      return !byId ? t(LETS_CHAT) : LETS_CHAT_SECTION;
    case SectionEnums.MEETTHETEAM:
      return !byId ? t(MEET_THE_TEAM) : MEET_THE_TEAM_SECTION;
    case SectionEnums.OURWORK:
      return !byId ? t(SOME_OF_OUR_WORK) : SOME_OF_OUR_WORK_SECTION;
    case SectionEnums.WHATWEDOBEST:
      return !byId ? t(WHAT_WE_DO_BEST) : WHAT_WE_DO_BEST_SECTION;
    case SectionEnums.OURTECHSTACK:
      return !byId ? t(OUR_TECH_STACK) : OUR_TECH_STACK_SECTION;
    case SectionEnums.CONTACTUS:
      return !byId ? t(CONTACT_US) : CONTACT_US_SECTION;
    default:
      return !byId ? t(LANDING) : LANDING_SECTION;
  }
};

export const getSectionByName = (sectionName: string): SectionEnums => {
  switch (sectionName) {
    case LANDING:
      return SectionEnums.LANDING;
    case LETS_CHAT:
      return SectionEnums.LETSCHAT;
    case MEET_THE_TEAM:
      return SectionEnums.MEETTHETEAM;
    case SOME_OF_OUR_WORK:
      return SectionEnums.OURWORK;
    case WHAT_WE_DO_BEST:
      return SectionEnums.WHATWEDOBEST;
    case OUR_TECH_STACK:
      return SectionEnums.OURTECHSTACK;
    case CONTACT_US:
      return SectionEnums.CONTACTUS;
    default:
      return SectionEnums.LANDING;
  }
};
