import Navbar from '../navbar/Navbar';
import './Header.scss';

const Header = () => {
  return (
    <div className='header'>
      <Navbar></Navbar>
    </div>
  );
};

export default Header;
