import cloudSolutions from './cloud-solutions.svg';
import webProjects from './web-projects.svg';
import mobileApps from './mobile-apps.svg';
import eCommerce from './e-commerce.svg';
import webDesign from './web-design.svg';

export const getIllustration = (key: string) => {
  switch (key) {
    case 'cloud-solutions':
      return cloudSolutions;
    case 'web-projects':
      return webProjects;
    case 'mobile-apps':
      return mobileApps;
    case 'e-commerce':
      return eCommerce;
    case 'web-design':
      return webDesign;
    default:
      return cloudSolutions;
  }
};
