import './ChatText.scss';

interface IChatText {
  spanParentClassName?: string;
  spanUserClassName?: string;
  spanBodyClassName?: string;
  username?: string;
  text: string;
}

/**
 * Faux chat text component.
 * @param spanParentClassName
 * OPTIONAL: CSS for parent span
 * @param spanUserClassName
 * OPTIONAL: CSS for username span
 * @param spanBodyClassName
 * OPTIONAL: CSS for body span
 * @param username
 * OPTIONAL: Display a username above the text message
 * @param text
 * REQUIRED: The chat text to display
 * @returns
 */
const ChatText = ({ spanParentClassName, spanUserClassName, spanBodyClassName, username, text }: IChatText) => {
  return (
    <>
      <span className={spanParentClassName}>
        <span className={spanUserClassName}>
          <strong>{username}</strong>
        </span>
        <span className={spanBodyClassName}>{text}</span>
      </span>
    </>
  );
};

export default ChatText;
