import React, { Fragment, useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { getSectionByType, MEET_THE_TEAM, MEET_THE_TEAM_SECTION, SectionEnums } from '../../../../enums/sectionsTypes';
import { NavBarContext } from '../../../../context/globalContext';
import './MeetTheTeam.scss';

const MeetTheTeam = () => {
  const { t } = useTranslation();
  const sectionHeaderText = getSectionByType(SectionEnums.MEETTHETEAM, false);
  const isMobile = useCheckMobileScreen();
  const { setActiveSection } = useContext(NavBarContext);

  const handleWaypointEnter = () => {
    setActiveSection(SectionEnums.MEETTHETEAM);
  };

  const handleWaypointLeave = () => {};

  const content = (id: number, name: string, link: string, image: string, imageHover: string) => (
    <div
      key={id}
      className={`meet-the-greet__avatar ${isMobile ? 'mobile' : 'desktop'}`}
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <div className={`top-layer center`}>
        <img className='normal' src={require(`../../../../assets/images/team/${image}.png`)} alt='Avatar'></img>
        <img className='onlyHover' src={require(`../../../../assets/images/team/${imageHover}.png`)} alt='Avatar'></img>
      </div>
      <div className={`bottom-layer `}>
        <h2 className='name-layer'>{name}</h2>
        <img src={require(`../../../../assets/images/team/${imageHover}.png`)} className='avatar-layer' alt='Avatar'></img>
        <a href={link} className='go-to-linkedin-layer' target='_blank'>
          {t('common.Click to open on Linkedin')}
        </a>
      </div>
      {isMobile && <span className='avatar-name'>{name}</span>}
    </div>
  );

  const renderContent = () => {
    return (
      <Fragment>
        <div className='p-grid'>
          {content(
            0,
            'Alex',
            t('meet-the-team.team.0.cv-url'),
            t('meet-the-team.team.0.memoji-img-path'),
            t('meet-the-team.team.0.memoji-hover-img-path'),
          )}
          {content(
            1,
            'Andrés',
            t('meet-the-team.team.1.cv-url'),
            t('meet-the-team.team.1.memoji-img-path'),
            t('meet-the-team.team.1.memoji-hover-img-path'),
          )}
          {content(
            2,
            'Joe',
            t('meet-the-team.team.2.cv-url'),
            t('meet-the-team.team.2.memoji-img-path'),
            t('meet-the-team.team.2.memoji-hover-img-path'),
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <Waypoint debug={false} topOffset={'211px'} bottomOffset={'909px'} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      <div className={`meet-the-team ${isMobile ? 'mobile' : 'desktop'}`}>
        <h1 id={MEET_THE_TEAM_SECTION}>{t(sectionHeaderText)}</h1>
        <div className='center p-text-center'>{renderContent()}</div>
        <div className='center p-text-center click-on '>{t('meet-the-team.click on any member')}</div>
      </div>
    </Waypoint>
  );
};

export default MeetTheTeam;
