import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { IconProp, config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Dropdown } from 'primereact/dropdown';
import './LanguageSelector.scss';
import { useEffect } from 'react';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const faGlobeIcon = faGlobe as IconProp;

  useEffect(() => {
    const storedLanguage = window.localStorage.getItem('language');
    if (storedLanguage !== null) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const changeLanguageHandler = async (lang: string) => {
    await i18n.changeLanguage(lang);
  };

  const changeLanguageTo = async (newLanguage: string) => {
    window.localStorage.setItem('language', newLanguage);
    await changeLanguageHandler(newLanguage);
  };

  const isCurrentLanguage = (languageCode: string) => {
    return i18n.language.toLowerCase() == languageCode ? 'active' : '';
  };

  const languages = [
    { label: 'EN', value: 'en' },
    { label: 'ES', value: 'es' },
    { label: 'CA', value: 'cat' },
    { label: 'DE', value: 'de' },
  ];

  const desktopLanguageSelector = () => {
    return (
      <div className='desktop language-selector'>
        <FontAwesomeIcon icon={faGlobeIcon}></FontAwesomeIcon>
        <Dropdown value={i18n.language} options={languages} onChange={e => changeLanguageTo(e.value)} />
      </div>
    );
  };

  const mobileLanguageSelector = () => {
    return (
      <div className='mobile language-selector center'>
        <div className={`${isCurrentLanguage('en')} circle`} onClick={() => changeLanguageTo('en')}>
          <span>EN</span>
        </div>
        <div className={`${isCurrentLanguage('es')} circle`} onClick={() => changeLanguageTo('es')}>
          <span>ES</span>
        </div>
        <div className={`${isCurrentLanguage('cat')} circle`} onClick={() => changeLanguageTo('cat')}>
          <span>CA</span>
        </div>
        <div className={`${isCurrentLanguage('de')} circle`} onClick={() => changeLanguageTo('de')}>
          <span>DE</span>
        </div>
      </div>
    );
  };

  if (!isMobile) {
    return <>{desktopLanguageSelector()}</>;
  } else {
    return <>{mobileLanguageSelector()}</>;
  }
};

export default LanguageSelector;
