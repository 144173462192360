import Landing from './sections/landing/Landing';
import LetsChat from './sections/lets-chat/LetsChat';
import MeetTheTeam from './sections/meet-the-team/MeetTheTeam';
import OurWork from './sections/our-work/OurWork';
import WhatWeDoBest from './sections/what-we-do/WhatWeDoBest';
import OurTechStack from './sections/our-tech-stack/OurTechStack';
import './index.scss';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const HomePage = () => {
  const isMobile = useCheckMobileScreen();
  return (
    <div className={`home-page ${isMobile ? 'mobile' : 'desktop'}`}>
      <Landing></Landing>
      <WhatWeDoBest></WhatWeDoBest>
      <OurWork></OurWork>
      <OurTechStack></OurTechStack>
      <MeetTheTeam></MeetTheTeam>
      <LetsChat></LetsChat>
    </div>
  );
};

export default HomePage;
