import { useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import koykuSide from '../../../../assets/images/design/koyku-side.svg';
import { NavBarContext } from '../../../../context/globalContext';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { LANDING, SectionEnums } from '../../../../enums/sectionsTypes';
import onTopScroll from '../../../../hooks/onTopScroll';
import './Landing.scss';

const Landing = () => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const { setActiveSection } = useContext(NavBarContext);

  const handleWaypointEnter = () => {
    setActiveSection(SectionEnums.LANDING);
  };

  const handleWaypointLeave = () => {};

  return (
    <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      <div id={LANDING} className={(isMobile ? 'mobile' : 'desktop') + ' landing p-d-flex'}>
        <div className={`${onTopScroll() ? '' : 'hide'} koyku-vertical p-mr-2`}>
          <img src={koykuSide} alt='Koyku Logo'></img>
        </div>
        <div className='landing_container p-mr-2 p-as-end'>
          <span className='landing_container__title'>{t('intro-section.HELLO')}</span>
          <p className='landing_container__text'>
            {t(
              'intro-section.Koyku provides tailored consulting services for a wide-array of digital products such as: Cloud, Web Development, Mobile App Development, E-commerce sites, and UI/UX design. Our international team brings to the table a combined 40 years of software engineering expertise with an eye for modern, secure, flexible, scalable, and highly engaging solutions for your business problems.',
            )}
          </p>
          <p className='landing_container__footer'>{t('intro-section.The koyku team')}</p>
        </div>
      </div>
    </Waypoint>
  );
};

export default Landing;
