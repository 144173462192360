export interface IWhatWeDoBestModel {
  id: number;
  title: string;
  imageUrl?: string;
  classname?: string;
}
export interface IWhatWeDoBestDetailModel {
  id: number;
  title: string;
  description: string;
  benefits: string[];
  imageUrl: string;
  why: string;
  classname: string;
}

export class WhatWeDoBestDetailModel implements IWhatWeDoBestDetailModel {
  id: number = 0;
  title: string = '';
  description: string = '';
  imageUrl: string = '';
  benefits: string[] = [];
  why: string = '';
  classname: string = '';
}
