import React, { useState } from 'react';
import { PRIVACYPOLICYLINK } from '../../../assets/documents';
import cookiesImage from '../../../assets/images/cookies.png';
import './CookieBanner.scss';

const CookieBanner = () => {
  const [cookieAccepted, setCookieAccepted] = useState(true);

  const persistence = window.localStorage.getItem('cookie-config');
  if (persistence == null && cookieAccepted != false) {
    setCookieAccepted(false);
  }

  const acceptCookie = () => {
    window.localStorage.setItem('cookie-config', 'accepted');
    setCookieAccepted(true);
  };

  return (
    <div className={`cookie-banner ${cookieAccepted ? 'hidden' : 'visible'}`}>
      <div className='cookie-content'>
        <img className='icon' src={cookiesImage}></img>

        <div className='text'>
          <span className='title'>We care about your Privacy</span>
          <p>
            <span>This web uses cookies to present and improve the content we offer. You can check our cookie policies</span>{' '}
            <a href={PRIVACYPOLICYLINK} target='_blank'>
              here
            </a>
          </p>
        </div>
      </div>
      <div className='button-group'>
        <button>Configure</button>
        <button className='main' onClick={() => acceptCookie()}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
