import { useTranslation } from 'react-i18next';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import koybotIcon from './../../../../../assets/images/design/koybot.svg';
import { KoyBotContext } from '../../../../../context/globalContext';
import { KoybotContextType } from '../../../../../models/koybotContextType';
import { useContext, useEffect, useState } from 'react';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import StepsSummary from '../../../../../components/ui/steps-summary/StepsSummary';
import theClientImg from '../../../../../assets/images/our-work/our-work-detail/the-client.svg';
import theChallengeImg from '../../../../../assets/images/our-work/our-work-detail/the-challenge.svg';
import ourSolutionImg from '../../../../../assets/images/our-work/our-work-detail/our-solution.svg';
import resultsImg from '../../../../../assets/images/our-work/our-work-detail/results.svg';
import { IOurWorkModel } from '../../../../../models/our-work/OurWorkModel';
import { LOGOS } from '../../../../../assets/images/tech-stacks';
import './OurWorkDetail.scss';

const OurWorkDetail = (currentWork: IOurWorkModel) => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const steps = [
    t('some-of-our-work-detail.The client'),
    t('some-of-our-work-detail.The challenge'),
    t('some-of-our-work-detail.Our solution'),
    t('some-of-our-work-detail.Results'),
  ];
  //Step's scroll % position min 0 max 1 (100%)
  const stepsPosition = [0, 0.46, 0.95, 1];

  const { koybotVisibility, setKoybotVisibility } = useContext(KoyBotContext) as KoybotContextType;
  const [currentSection, setCurrentSection] = useState('');

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setCurrentSection(`${t('some-of-our-work-detail.The client')}`);
  }, []);

  useEffect(() => {
    let current = currentSection;
    if (offset <= stepsPosition[0]) {
      current = steps[0];
    } else if (offset > stepsPosition[0] && offset <= stepsPosition[1]) {
      current = steps[1];
    } else if (offset > stepsPosition[1] && offset <= stepsPosition[2]) {
      current = steps[2];
    } else if (offset > stepsPosition[2] && offset <= stepsPosition[3]) {
      current = steps[3];
    }
    if (currentSection !== current) {
      setCurrentSection(current);
    }
  }, [offset]);

  const moveToSection = (newSection: string) => {
    if (scrollElement !== undefined && scrollElement !== null) {
      const windowHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
      const index = steps.indexOf(newSection);
      scrollElement?.scrollTo(0, stepsPosition[index] * windowHeight);
    }
  };

  const scrollElement = document.querySelector('#our-work-dialog_content');

  if (scrollElement != null) {
    scrollElement.addEventListener(
      'scroll',
      event => {
        const totalScroll = scrollElement.scrollTop;
        const windowHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
        const scroll = totalScroll / windowHeight;
        if (scroll != offset) {
          setOffset(scroll);
        }
      },
      { passive: true },
    );
  }

  const renderStepsTimeLine = () => {
    return (
      <>
        <div className='steps'>
          <div className='stepDate'>{currentWork.date}</div>
          <StepsSummary key={Math.random()} steps={steps} current={currentSection} moveToSection={moveToSection}></StepsSummary>
        </div>
      </>
    );
  };

  const getStack = (stack: any) => {
    return (
      <div key={stack.name} className='stack-item'>
        <div className='gradient-background'></div>
        <Image src={stack.logo} alt={stack.name} />
      </div>
    );
  };

  const content = (step: string) => {
    return (
      <div className='step-detail' id={step} key={step}>
        <span className='step-detail__title'>{step}</span>
        <div className='step-detail__content'>
          <div className='gradient-background'></div>
          <div className='p-grid step-detail__content-row center'>{getStepContent(step)}</div>
        </div>
      </div>
    );

    function getStepContent(step: string) {
      let content = '';
      let contentImg = '';
      let stackImg: any[] = [];
      switch (step) {
        case steps[0]:
          content = currentWork.detail.theClient;
          contentImg = theClientImg;
          break;
        case steps[1]:
          content = currentWork.detail.theChallenge;
          contentImg = theChallengeImg;
          break;
        case steps[2]:
          content = currentWork.detail.ourSolution;
          contentImg = ourSolutionImg;
          stackImg = LOGOS.filter(x => currentWork.stack.includes(x.name));
          break;
        case steps[3]:
          content = currentWork.detail.results;
          contentImg = resultsImg;
          break;
        default:
          break;
      }

      return (
        <>
          <div className='p-xs-12 p-lg-3 image-column'>
            <Image src={contentImg} alt='our-work' />
          </div>
          <div className='p-xs-12 p-lg-9 text'>{content}</div>
          <div className={`p-col-12 stack p-grid ${stackImg.length > 0 ? '' : 'hidden'}`}>
            <div className='p-col-12 center title'>{t('some-of-our-work-detail.Technologies used')}</div>
            <div className='p-col-12 stack-group center'>{stackImg.map(stackTechnology => getStack(stackTechnology))}</div>
          </div>
        </>
      );
    }
  };

  const render = () => (
    <div className={`our-work-detail ${isMobile ? 'mobile' : 'desktop'}`}>
      {renderStepsTimeLine()}
      <div className='scrollable-detail p-grid'>
        <div className='our-work-description'>{currentWork.description}</div>
        <div className='steps-details'>{steps.map((stepName, index) => content(stepName))}</div>
        <div className='external-chat center'>
          <Image className='koybotIcon' src={koybotIcon} height={'100%'} alt='koybot'></Image>
          <Button
            className='koyku-button'
            label={t('chatbot.Ask for an estimate')?.toString()}
            onClick={() => {
              setKoybotVisibility(true);
            }}
          ></Button>
        </div>
      </div>
    </div>
  );

  return <>{render()}</>;
};

export default OurWorkDetail;
