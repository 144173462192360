import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';
import { NavBarContext } from '../../../../context/globalContext';
import { SOME_OF_OUR_WORK, SectionEnums, getSectionByType, SOME_OF_OUR_WORK_SECTION } from '../../../../enums/sectionsTypes';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import OurWorkDetail from './detail/OurWorkDetail';
import SidebarControl from '../../../../components/ui/sidebar-control/SidebarControl';
import Footer from '../../../../components/layout/footer/Footer';
import { IOurWorkModel, OurWorkModel } from '../../../../models/our-work/OurWorkModel';
import { getImage } from '../../../../assets/images/our-work/card';
import './OurWork.scss';

const OurWork = () => {
  const { t } = useTranslation();
  const [currentWork, setCurrentWork] = useState<IOurWorkModel>(new OurWorkModel());
  const { setActiveSection } = useContext(NavBarContext);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayFullScreen, setDisplayFullScreen] = useState(false);
  const isMobile = useCheckMobileScreen();
  const sectionHeaderText = getSectionByType(SectionEnums.OURWORK, false);

  const handleWaypointEnter = () => {
    setActiveSection(SectionEnums.OURWORK);
  };

  const handleWaypointLeave = () => {};

  const openDetail = (currentWork: IOurWorkModel) => {
    setCurrentWork(currentWork);
    if (isMobile) {
      setDisplayFullScreen(true);
    } else {
      setDisplayModal(true);
    }
  };

  const ourWorks = t('some-of-our-work', { returnObjects: true }) as Array<IOurWorkModel>;

  const content = (ourWork: IOurWorkModel) => (
    <div key={ourWork.id} className={`p-col-6 advanced-card ${isMobile ? 'mobile' : 'desktop'}`} onClick={() => openDetail(ourWork)}>
      <div className='gradient-background'></div>
      <div className='advanced-card__header'>
        <Image src={getImage(ourWork.image)} alt={ourWork.title} className='image-mask' />
        <p className='advanced-card__title'>
          <span className='advanced-card__title-text'>{ourWork.title}</span>
          <span className='advanced-card__title-date'>{ourWork.date}</span>
        </p>
      </div>
      <p className='advanced-card__content ellipsis center'>{ourWork.description}</p>
    </div>
  );

  const ourWorkDetail = (
    <OurWorkDetail
      id={currentWork.id}
      title={currentWork.title}
      date={currentWork.date}
      description={currentWork.description}
      detail={currentWork.detail}
      image={currentWork.image}
      stack={currentWork.stack}
    ></OurWorkDetail>
  );

  const render = () => {
    let ourWorkContent = Array<ReactElement>();
    ourWorks.forEach(ourWork => {
      ourWorkContent.push(content(ourWork));
    });
    return ourWorkContent;
  };

  return (
    <Waypoint debug={false} topOffset={'409px'} bottomOffset={'461px'} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      <div className='our-work'>
        <h1 id={SOME_OF_OUR_WORK_SECTION}>{t(sectionHeaderText)}</h1>
        <div className={`p-grid center distribute ${isMobile ? 'mobile' : 'desktop'}`}>{render()}</div>
        <Dialog
          header={sectionHeaderText}
          visible={displayModal}
          modal
          style={{ width: '839px' }}
          onHide={() => setDisplayModal(false)}
          draggable={false}
          resizable={false}
          className='our-work-dialog'
          blockScroll={true}
          id={'our-work-dialog'}
        >
          {ourWorkDetail}
        </Dialog>
        <Sidebar visible={displayFullScreen} fullScreen blockScroll={true} onHide={() => setDisplayFullScreen(false)}>
          <SidebarControl header={currentWork.title} onBackClick={() => setDisplayFullScreen(false)} showBack={true}></SidebarControl>
          {ourWorkDetail}
          <Footer></Footer>
        </Sidebar>
      </div>
    </Waypoint>
  );
};

export default OurWork;
