import { useContext } from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { t } from 'i18next';
import { KoybotContextType } from '../../../../../models/koybotContextType';
import koybotIcon from '../../../../../assets/images/design/koybot.svg';
import { KoyBotContext } from '../../../../../context/globalContext';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import { IWhatWeDoBestDetailModel } from '../../../../../models/what-we-do-best/WhatWeDoBestModel';
import { getIllustration } from '../../../../../assets/images/what-we-do-best/what-we-do-best-detail';
import './WhatWeDoBestDetail.scss';

const WhatWeDoBestDetail = (detail: IWhatWeDoBestDetailModel) => {
  const { setKoybotVisibility } = useContext(KoyBotContext) as KoybotContextType;
  const isMobile = useCheckMobileScreen();
  const benefit = (bNumber: number, bText: string) => {
    return (
      <div key={bNumber} className='benefit p-lg-6 p-md-12'>
        <div className='benefit-number'>
          <span>{bNumber}</span>
        </div>
        <span className='benefit-text'>{bText}</span>
      </div>
    );
  };

  const benefits = detail.benefits.map((item, index) => {
    let bNumber = index + 1;
    return benefit(bNumber, item);
  });

  const render = () => {
    return (
      <div className={`content ${isMobile ? 'mobile' : 'desktop'}`}>
        <div className='content__header center'>
          <Image src={getIllustration(detail.imageUrl)} alt={detail.title}></Image>
        </div>
        <div className='content__main'>
          <div className='p-sm-12 p-lg-12 section'>
            <h3>{`${t('common.Benefits')}`}</h3>
            <div className='benefits-row p-grid'>{benefits}</div>
          </div>
          <div className='p-sm-12 p-lg-12 section'>
            <h3>{detail.why}</h3>
            <div className='why'>
              <div className='gradient-background'></div>
              <p>{detail.description}</p>
            </div>
          </div>

          <div className='p-sm-12 p-lg-8 p-lg-offset-4'>
            <div className='includes-content p-col-12'>
              <div className='includes-row'>
                <div className='p-col-12'>
                  <Image className='koybotIcon' src={koybotIcon} height={'100%'}></Image>
                  <Button
                    className='koyku-button'
                    label={t('chatbot.Ask for an estimate')?.toString()}
                    onClick={() => {
                      setKoybotVisibility(true);
                    }}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{render()}</>;
};

export default WhatWeDoBestDetail;
