import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import HomePage from './pages/home';
import Header from './components/layout/header/Header';
import Footer from './components/layout/footer/Footer';
import FauxChat from './pages/faux-chat/FauxChat';
import useCheckMobileScreen from './hooks/useCheckMobileScreen';
import WhatWeDoBestDetail from './pages/home/sections/what-we-do/detail/WhatWeDoBestDetail';
import { KoyBotContext } from './context/globalContext';
import NavbarProvider from './providers/navbarProvider';
import CookieBanner from './components/shared/cookie/CookieBanner';
import './App.scss';

function App() {
  const isMobile = useCheckMobileScreen();
  const [koybotVisibility, setKoybotVisibility] = useState(false);
  return (
    <KoyBotContext.Provider value={{ koybotVisibility, setKoybotVisibility }}>
      <NavbarProvider>
        <div className={(isMobile ? 'mobile' : 'desktop') + ' App'}>
          <Header></Header>
          <Panel className='main-content'>
            <Routes>
              <Route path='/' element={<HomePage />}></Route>
              {/* <Route path='what-we-do-best/detail' element={<WhatWeDoBestDetail />}></Route> */}
              <Route path='chat' element={<FauxChat />}></Route>
              {/* <Route path='*' element={<NotFound />}></Route> */}
            </Routes>
          </Panel>
          <Footer></Footer>
          <CookieBanner></CookieBanner>
        </div>
      </NavbarProvider>
    </KoyBotContext.Provider>
  );
}

export default App;
