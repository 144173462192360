/** The model used in the contact us form */
export class ContactUsModel {
  constructor() {}

  fullName: string = '';
  email: string = '';
  phoneNumber?: string = '';
  message: string = '';
  hasAgreed: boolean = false;
}

/** The validation properties for the model */
export const VALIDATIONS = {
  maxFullName: 100,
  maxEmail: 250,
  maxPhoneNumber: 20,
  maxMessage: 500,
};

//TODO: Make generic and inherit from model base class
/** Helper method to set states of model properties */
export type SetPropertyFunction = <T extends keyof ContactUsModel>(property: T, value: ContactUsModel[T]) => void;
