import { ReactChild, ReactChildren, useState } from 'react';
import { Link } from 'react-router-dom';
import NavbarContext, { defaultNavBarState } from '../context/navbarContext';
import { getSectionByType, SectionEnums } from '../enums/sectionsTypes';
import { useNavigate } from 'react-router';

interface ProviderProps {
  children: ReactChild | ReactChildren;
}

const NavbarProvider = ({ children }: ProviderProps) => {
  const [activeSection, setActiveSection] = useState(defaultNavBarState.activeSection);
  const navigate = useNavigate();

  const setActiveSectionHandler = (sectionType: SectionEnums): void => {
    const section = getSectionByType(sectionType, true);
    setActiveSection(section);
  };

  const goToSectionHandler = (sectionName: string): void => {
    //TODO: We can improve this later but, for now it works.
    //TODO: Scroll to top when clicking koyku icon.
    window.location.replace('#' + sectionName);

    //navigate('#' + sectionName, { replace: true });
  };

  const navbarContext = {
    activeSection: activeSection,
    setActiveSection: setActiveSectionHandler,
    goToSection: goToSectionHandler,
  };

  return (
    <NavbarContext.Provider value={navbarContext}>
      <>{children}</>
    </NavbarContext.Provider>
  );
};

export default NavbarProvider;
