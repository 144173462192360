import axios from 'axios';
import { apiRoutes } from '../../config/routes/apiRoutes';

//pass new generated access token here
//const token = accessToken

//apply base url for axios
const getApiUrl = () => {
  return process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_ENDPOINT_DEV : process.env.REACT_APP_API_ENDPOINT_PROD;
};

const API_URL = getApiUrl();

const axiosApi = axios.create({
  baseURL: API_URL,
  // Needed for CSRF token validation
  withCredentials: true,
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export async function get<T>(url: string, config = {}) {
  return await axiosApi.get<T>(url, { ...config }).then(response => response.data);
}

export async function post<T>(url: string, data: T, config = {}) {
  //await getCsrfToken();
  return axiosApi.post<T>(url, { ...data }, { ...config }).then(response => response.data);
}

export async function put<T>(url: string, data: T, config = {}) {
  //await getCsrfToken();
  return axiosApi.put<T>(url, { ...data }, { ...config }).then(response => response.data);
}

export async function del<T>(url: string, config = {}) {
  //await getCsrfToken();
  return await axiosApi.delete<T>(url, { ...config }).then(response => response.data);
}

export function getCookie(key: string) {
  var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

export async function getCsrfToken() {
  const data = (await (await axiosApi.get<string>(apiRoutes.CSRF))?.data) || '';
  axiosApi.defaults.headers.common['X-XSRF-TOKEN'] = data;
}
