export const ANDROID_LOGO = require('./android-logo.png');
export const ANGULAR_LOGO = require('./angular-logo.png');
export const AZURE_LOGO = require('./azure-logo.png');
export const DEVOPS_LOGO = require('./devops-logo.png');
export const FIGMA_LOGO = require('./figma-logo.png');
export const FIREBASE_LOGO = require('./firebase-logo.png');
export const INVISION_LOGO = require('./invision-logo.png');
export const IONIC_LOGO = require('./ionic-logo.png');
export const MYSQL_LOGO = require('./mysql-logo.png');
export const NET_LOGO = require('./net-logo.png');
export const NODEJS_LOGO = require('./nodejs-logo.png');
export const PHP_LOGO = require('./php-logo.png');
export const REACT_LOGO = require('./react-logo.png');
export const REACT_NATIVE_LOGO = require('./react-native-logo.png');
export const SKETCH_LOGO = require('./sketch-logo.png');
export const SQL_LOGO = require('./sql-logo.png');
export const VUE_LOGO = require('./vue-logo.png');
export const XCODE_LOGO = require('./xcode-logo.png');

export const LOGOS = [
  { logo: ANGULAR_LOGO, name: 'angular' },
  { logo: REACT_LOGO, name: 'react' },
  { logo: VUE_LOGO, name: 'vue' },
  { logo: NODEJS_LOGO, name: 'node.js' },
  { logo: NET_LOGO, name: '.net' },
  { logo: XCODE_LOGO, name: 'xcode' },
  { logo: ANDROID_LOGO, name: 'android' },
  { logo: REACT_NATIVE_LOGO, name: 'react native' },
  { logo: IONIC_LOGO, name: 'ionic' },
  { logo: PHP_LOGO, name: 'php' },
  { logo: FIREBASE_LOGO, name: 'firebase' },
  { logo: AZURE_LOGO, name: 'azure' },
  { logo: DEVOPS_LOGO, name: 'DevOps' },
  { logo: SQL_LOGO, name: 'MS SQL' },
  { logo: MYSQL_LOGO, name: 'MySQL' },
  { logo: SKETCH_LOGO, name: 'sketch' },
  { logo: FIGMA_LOGO, name: 'figma' },
  { logo: INVISION_LOGO, name: 'invision' },
];
